import Vue from 'vue'

const components = [
    'Picture',
    'VText',
    'VButton',
    'Group',
    'RectShape',
    'Link',
    'Music',
    'Carousel',
    'Video',
    'TimeLine',
    'LineT',
]

components.forEach(key => {
    Vue.component(key, () => import(`@/custom-component/${key}`))
})
