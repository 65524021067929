// 公共样式
export const commonStyle = {
    rotate: 0, 
}

export const commonAttr = {
    animations: [],
    events: {},
    groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
    isLock: false, // 是否锁定组件
}

// 编辑器左侧组件列表
const list = [
    {
        component: 'v-text',
        label: '文字',
        propValue: '双击编辑文字',
        icon: 'wenben',
        style: {
            width: 200,
            height: 22,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1,
            letterSpacing: 0,
            color: '',
            textAlign: 'left',
            opacity: 1,
        },
    },
    {
        component: 'v-button', 
        label: '按钮', 
        propValue: '按钮',
        icon: 'anniu',
        style: {
            width: 100,
            height: 34,
            borderWidth: '',
            borderColor: '',
            borderRadius: '',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '',
            letterSpacing: 0,
            color: '',
            backgroundColor: '',
            opacity: 1,
        },
    },
    {
        component: 'Picture', 
        label: '图片', 
        icon: 'tupian_o',
        propValue: require('@/assets/empty.jpg'),
        style: {
            width: 360,
            height: 225,
            borderRadius: 2,
            borderWidth: 0,
            borderColor: '',
            picSrc: '',
            borderStyle: 'solid',
            opacity: 1,
        },
    },
    {
        component: 'rect-shape',
        label: '矩形',
        propValue: '',
        icon: 'xingzhuang-juxing',
        style: {
            width: 200,
            height: 100,
            borderColor: '#000',
            borderWidth: 1,
            backgroundColor: '',
            borderStyle: 'solid',
            borderRadius: '5',
            verticalAlign: 'middle',
            opacity: 1,
        },
    },
    {
        component: 'Link',
        label: '链接',
        linkUrl: 'http://loveyou520.top/',
        linkLable: '点击查看',
        icon: 'lianjie',
        style: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '',
            letterSpacing: 0,
            color: '',
            backgroundColor: '',
            opacity: 1,
        },
    },
    {
        component: 'Music',
        label: '音乐',
        propValue: require('@/assets/music/temBandrui.mp3'),
        icon: 'yinle',
        style: {
            musicSrc: '',
        },
    },
    {
        component: 'Carousel',
        label: '轮播',
        propValue: [require('@/assets/img/banner_default.jpg')],
        icon: 'lunboxiaoguo',
        style: {
            width: 400,
            height: 150,
        },
    },
    {
        component: 'Video',
        label: '视频',
        propValue: require('@/assets/defaultVideo.mp4'),
        icon: 'shipin',
        style: {
            
        },
    },
    {
        component: 'TimeLine',
        label: '故事',
        propValue: [{
            dateTime: '2015-08-06',
            title: '相遇',
            content: '遇见你是故事的开始，走到底是余生的欢喜。',
        },
        {
            dateTime: '2018-08-06',
            title: '相爱',
            content: '惟求今世有幸只与一人于微时相识、彼此相爱、不离不弃、相守一生，只是何其难也。',
        },
        {
            dateTime: '2020-08-06',
            title: '相守',
            content: '平淡中的相守,最是美丽,自然温馨,,风雨中的陪伴,最是温暖,相惜相依。',
        }],
        icon: 'rili',
        style: {
            
        },
    },
    {
        component: 'LineT',
        label: '线条',
        icon: 'fengexian',
        style: {
            width: 200,
            height: 48,
            opacity: 1,
        },
    },
]

for (let i = 0, len = list.length; i < len; i++) {
    const item = list[i]
    item.style = { ...commonStyle, ...item.style }
    list[i] = { ...commonAttr, ...item }
}

export default list